.page-footer {
  background: map-get($grays, '900');
  position: relative;

  .footer {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 0;

    &.content ul {
      padding-right: 2.5rem;
    }

    .newsletter {
      background-color: $primary;
      display: flex;
      max-width: 100%;
      width: 100%;

      .content {
        width: 100%;
        max-width: 1310px;
        padding: 0 1.5rem;
        margin: 0 auto;
        position: relative;
        display: flex;

        @include media-breakpoint-up(lg) {
          padding: 0.5rem 1.5rem;
        }

        .btn-download-catalogue {
          padding: 1.6rem 2.2rem 1.6rem 5.1rem;
          position: absolute;
          right: 1.5rem;
          top: 50%;
          transform: translateY(-50%);
          text-wrap: nowrap;

          &::before {
            left: 18px;
          }
        }
      }

      .nsletter-wrapper {
        display: flex;
        flex-direction: row;
        margin-bottom: 0;
        flex-grow: 1;

        @include media-breakpoint-down(lg) {
          padding-right: 2.7rem;
        }

        .sign-up-text {
          margin: 2.3rem 3.4rem 1.7rem 0;
          color: $white;
          text-transform: uppercase;
          font-weight: 500;
          font-family: $headings-font-family;
          white-space: nowrap;
          display: flex;
          align-items: center;
          max-width: 264px;

          span {
            font-size: 2.3rem;
            line-height: 1.1;
          }

          @include media-breakpoint-down(lg) {
            display: none;
          }
        }

        .control {
          position: relative;
          display: flex;
          flex-direction: row;
          flex: 1 0 600px;

          @include media-breakpoint-down(lg) {
            margin-left: 15px;
            flex: 1 0 auto;
          }

          .nsletter-input-wrapper {
            max-width: 487px;
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;

            .mage-error[for='newsletter'] {
              position: absolute;
              margin-top: 0;
              top: -18px;
              white-space: nowrap;
            }
          }

          input {
            max-width: 487px;
            width: 100%;
            display: block;
            padding-top: 1.7rem;
            padding-bottom: 1.7rem;
            padding-left: 4.6rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            margin-right: 0;
            height: 50px;
            color: $old-price-gray;
            font-weight: 700;
            outline: 0;

            @include media-breakpoint-down(lg) {
              max-width: 100%;
              font-size: 1.4rem;
            }
          }

          >span {
            position: absolute;
            top: 50%;
            left: 14px;
            transform: translateY(-50%);
          }

          .actions {
            width: auto;

            button {
              margin-top: 1rem;
              margin-bottom: 1rem;
              border-left: 0;
              background-color: $gray-900;
              position: relative;
              padding-left: 4.8rem;
              height: calc(100% - 2rem);

              span {
                font-size: 1.5rem;
                line-height: 1.46;
              }

              span::before {
                position: absolute;
                content: '';
                background-image: url('../images/paper-plane.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 24px 24px;
                height: 24px;
                width: 24px;
                left: 17px;
                top: 13px;
              }
            }

            button:hover {
              background-color: $gray-900;

              span {
                color: $white;
              }
            }
          }
        }
      }
    }

    .footer-content-wrapper {
      display: flex;
      flex-direction: row;
      color: $white;
      margin-top: 6.9rem;
      max-width: 1310px;
      width: 100%;
      padding: 0 1.5rem;
      margin: 0 auto;

      @include media-breakpoint-down(md) {
        padding: 0 3.8rem;
      }

      a {
        text-decoration: none;
        color: $body-bg;

        &:hover {
          color: $body-bg;
        }
      }

      @include media-breakpoint-down(md) {
        flex-direction: column-reverse;
      }

      .column {
        flex: 1 1 auto;

        &.group-wrapper {
          display: flex;
          flex-direction: row;

          &>div {
            flex-grow: 1;
          }

          @include media-breakpoint-down(md) {
            flex-direction: column;
            width: 100%;
            margin: 0 auto;

            .contact {
              order: -1;
            }

            .payments {
              visibility: hidden;
              width: 0;
              height: 0;
            }
          }
        }

        .wrapper {
          margin: 1rem;

          .title {
            font-size: 1.8rem;
            font-family: $headings-font-family;
            font-weight: 500;
            color: $white;
            margin-bottom: 2.2rem;
            text-transform: uppercase;

            &::after {
              content: '';
              height: 5px;
              width: 30px;
              background: $primary;
              display: block;
            }

            @include media-breakpoint-down(md) {
              background-image: url('../images/plus.svg');
              background-size: 1.5rem 1.5rem;
              background-position: right top 0.5rem;
              background-repeat: no-repeat;

              &:not(.collapsed) {
                background-image: url('../images/minus.svg');
              }

              &::after {
                width: 100%;
              }
            }
          }

          .items,
          .svg-items {
            font-size: 1.4rem;
          }

          @include media-breakpoint-up(lg) {

            .dont-collapse-sm {
              display: block !important;
              height: auto !important;
              visibility: visible;
            }
          }
        }

        .payments {

          .svg-items {
            display: flex;
            flex-direction: row;

            @include media-breakpoint-up(lg) {
              margin-top: 7.3rem;
            }

            div {
              padding: 0 0.4rem;
            }
          }
        }

        .contact {

          .contact-wrapper {
            display: flex;
            flex-direction: column;
            max-width: 28rem;
            margin-right: auto;
            margin-left: auto;

            .mgz-element-column > .mgz-element-inner {
              padding: 0;
            }

            .bottom-logo {
              margin-bottom: 1.4rem;

              svg {
                max-width: 100%;
                height: auto;
              }
            }

            .address span {
              display: block;
              font-size: 1.6rem;
              line-height: 2.8rem;
            }

            .phone {
              color: #fff;
              display: flex;
              flex-direction: column;
              margin-top: 0;

              div {

                svg {
                  margin-top: -0.5rem;
                  margin-right: 0.8rem;
                }

                .btn-download-catalogue {
                  display: block;
                  margin-top: 3.5rem;

                  @include media-breakpoint-up(lg) {
                    display: none;
                  }
                }

                a:not(.btn-download-catalogue) {
                  font-family: $headings-font-family;
                  text-decoration: none;
                  color: $white;
                  font-size: 2.1rem;
                }
              }

              @include media-breakpoint-down(md) {
                flex-direction: column;
                margin-bottom: 2.5rem;
              }
            }
          }
        }

        @include media-breakpoint-down(md) {

          .contact,
          .payments {
            flex-basis: 0;
          }

          .contact .contact-wrapper {
            max-width: none !important;
            width: 100%;
            margin-right: 1rem;
            margin-left: 1rem;
          }
        }
      }
    }
  }

  .item-hide {
    visibility: hidden;
    width: 0;
    height: 0;

    svg {
      width: 0;
      height: 0;
    }

    @include media-breakpoint-down(md) {
      visibility: visible;
      margin: 4rem 0 3.6rem;
      width: auto;
      height: auto;
      order: -1;

      .svg-items {
        display: flex;
        flex-direction: row;

        div {
          padding: 0 0.6rem;

          svg {
            width: auto;
            height: auto;
          }
        }
      }
    }
  }
}

.bottom-copyright {
  display: flex;
  background: $body-bg;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1.1rem 0;

  .disclaimer {
    flex-basis: 100%;
    text-align: center;
  }

  div {
    padding: 0 1rem;
    position: relative;
    font-size: 1.2rem;
    color: $black;

    @include media-breakpoint-down(xs) {
      padding: 0 0.5rem;
    }

    &:not(:last-child)::after {
      content: '';
      height: 1rem;
      width: 0.1rem;
      background: map-get($grays, '900');
      right: 0;
      top: 0.5rem;
      position: absolute;
    }

    a {
      text-decoration: none;
      color: $black;
    }
  }
}